<template>
  <div class="products">
    <div class="three-btns container mb-4">
      <button @click="$router.push('/')">{{ $t("Main") }} /</button>
      <button>{{ $t("Packages") }}</button>
    </div>

    <!-- categories and recently arrived -->
    <div class="categories mt-11 mt-5 px-4">
      <div class="row">
        <div class="col-md-3 col-xs-12">
          <button
            @click="() => (showFilter = !showFilter)"
            class="p-2 rounded d-lg-none d-flex align-items-center gap-2"
          >
            <span class="pi pi-filter"></span>
            <!-- <span>Filter</span> -->
          </button>
          <div class="sidebarsearch mt-2 p-3" v-if="showFilter">
            <div class="mb-3">
              <h3>{{ $t("Categories") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of categoriesList"
                :key="'categories_' + item._id"
              >
                <Checkbox
                  :id="'categories_' + item._id"
                  name="categoriesId"
                  :value="item._id"
                  v-model="body.categoriesId"
                  :inputId="'categories_' + item._id"
                />
                <label :for="'categories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>
            <div class="mb-3">
              <h3>{{ $t("sections") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of subcategoriesList"
                :key="'subcategories_' + item._id"
              >
                <Checkbox
                  :id="'subcategories_' + item._id"
                  name="subCategoriesId"
                  :value="item._id"
                  v-model="body.subCategoriesId"
                  :inputId="'subcategories_' + item._id"
                />
                <label :for="'subcategories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("the price") }}</h3>
              <div class="row mb-4">
                <div class="col-md-6">{{ body.price[1] }} {{ $t("r.s") }}</div>
                <div class="col-md-6 text-left">
                  {{ body.price[0] }} {{ $t("r.s") }}
                </div>
              </div>
              <div>
                <Slider
                  v-model="body.price"
                  :min="0"
                  :max="100000"
                  :range="true"
                />
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("the shop") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of vendorsList"
                :key="'vendors_' + item._id"
              >
                <Checkbox
                  :id="'vendors_' + item._id"
                  name="vendorsId"
                  :value="item._id"
                  v-model="body.vendorsId"
                  :inputId="'vendors_' + item._id"
                />
                <label :for="'vendors_' + item._id">
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-xs-12">
          <div class="packages m-2">
            <h1 class="cat-head mb-4">{{ $t("Packages") }}</h1>
            <div style="min-height: 700px">
              <div class="m-5 text-center" v-if="list.length == 0">
                <img
                  src="../../assets/images/notFoundIllustration.svg"
                  class="mt-5"
                  alt=""
                  style="max-width: 300px"
                />
              </div>
              <div class="row">
                <div class="col-md-4" v-for="(item, inex) of list" :key="inex">
                  <div
                    class="pack"
                    @click="$router.push('/package/' + item._id)"
                    style="cursor: pointer"
                  >
                    <div
                      class="d-flex w-100 flex-row-reverse justify-content-end"
                    >
                      <img
                        v-for="(src, i) in item.packageImages"
                        :key="i"
                        v-if="i < 4"
                        :src="$baseUploadURL + src.name"
                        alt=""
                      />
                    </div>
                    <div
                      class="w-100 mt-3 d-flex align-items-end justify-content-between"
                    >
                      <div class="">
                        <h3 class="mb-2 fw-bold">
                          {{ $getByLang(item.name) }}
                        </h3>
                        <p class="mb-0">
                          {{ item.itemsCount }} {{ $t("Elements") }}
                        </p>
                      </div>
                      <div class="price">{{ item.price }}{{ $t("r.s") }}</div>
                    </div>
                  </div>
                  <!-- <div
                    class="pack"
                    @click="$router.push('/package/' + item._id)"
                  >
                    <div>
                      <img
                        v-if="item.packageImages.length > 0"
                        :src="$baseUploadURL + item.packageImages[0].name"
                        class="pimg1"
                        alt=""
                      />
                      <img
                        :src="
                          item.packageImages.length > 1
                            ? $baseUploadURL + item.packageImages[1].name
                            : '/images/default-image.jpg'
                        "
                        class="pimg2"
                        alt=""
                      />

                      <div class="pimg3">
                        <img
                          :src="
                            item.packageImages.length > 2
                              ? $baseUploadURL + item.packageImages[2].name
                              : '/images/default-image.jpg'
                          "
                          alt=""
                        />
                      </div>

                      <div class="pimg4">
                        <img
                          :src="
                            item.packageImages.length > 3
                              ? $baseUploadURL + item.packageImages[3].name
                              : '/images/default-image.jpg'
                          "
                          alt=""
                        />
                        <div class="ck" v-if="item.packageImages.length > 3">
                          {{ item.packageImages.length - 4 }}
                        </div>
                      </div>
                    </div>
                    <div class="row contc">
                      <div class="col-6">
                        <h5>{{ $getByLang(item.name) }}</h5>
                        <p>{{ item.itemsCount }} {{ $t("items") }}</p>
                      </div>
                      <div class="col-6 text-center">
                        <div class="price">
                          {{ item.price }} {{ $t("r.s") }}
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- <Paginator  :pageNumber="pageNum"
              v-if="list.length > 0"
              style="direction: ltr"
              :rows="limit"
              :totalRecords="totalItemsCount"
              @page="onPage($event)"
            ></Paginator> -->
            <infinite-loading @infinite="loadMore" :identifier="pageNum">
              <div slot="no-more">{{ $t('No more data') }}</div>
              <div slot="no-results">{{ $t('No results') }}</div>
            </infinite-loading>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import InfiniteLoading from "vue-infinite-loading";
export default {
components: { InfiniteLoading },
  head: {
    title: function () {
      return {
        inner: this.$t("Packages"),
      }

    },
  },

  data() {
    return {
      pageNum:
        sessionStorage.homeHerePagePackages == "packages"
          ? parseInt(sessionStorage.homeHerePageNumberPackages)
          : 1,
      body: {
        categoriesId: [],
        subCategoriesId: [],
        colorsList: {
          colorId: null
        },
        sizes: {
          sizeId: []
        },
        price: [15000, 77000],
        vendorsId: [],
        materialsId: []
      },
      categoriesList: [],
      subcategoriesList: [],
      sizeList: [],
      colorsList: [],
      vendorsList: [],

      materialsList: [],
      list: [],
      totalItemsCount: 0,

      limit: 12,
      page: 1,
      q: null,
      one: {
        categoriesId: {
          name: {
            ar: null,
            en: null
          }
        },
        name: {
          ar: null,
          en: null
        },
        bannerSubCategoryImage: null
      },

      showFilter: false
    };
  },
  mounted() {
    if (window.innerWidth > 992) {
      this.showFilter = true;
    }
  },
  methods: {
    $goToSave(link) {
      sessionStorage.setItem("homeHerePagePackages", "packages");
      sessionStorage.setItem("homeHerePageNumberPackages", this.pageNum);
      this.$router.push(link);
    },
    onPage(event) {
      //event.page: New page number
      this.pageNum = event.page + 1;
      this.getData(event.page + 1);
      sessionStorage.setItem("homeHerePagePackages", "packages");
      sessionStorage.setItem("homeHerePageNumberPackages", this.pageNum);
      window.scrollTo(0, 0);
    },
    loadMore($state) {
      this.pageNum += 1;
      this.getData2(this.pageNum)
        .then((res) => {
          if (res.length) {
            this.list = [...this.list, ...res];
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(() => {
          $state.complete();
        });
    },
    async getData2(page = 1) {

      let obj = {};
      if (this.q) {
        obj = {
          q: this.q
        };
      }

      if (this.body.categoriesId.length > 0) {
        obj.categoriesId = { $in: this.body.categoriesId };
      }
      if (this.body.subCategoriesId.length > 0) {
        obj.subCategoriesId = { $in: this.body.subCategoriesId };
      }
      if (this.body.vendorsId && this.body.vendorsId.length > 0) {
        obj.vendorsId = { $in: this.body.vendorsId };
      }
      if (this.body.materialsId.length > 0) {
        obj.materialsId = { $in: this.body.materialsId };
      }
      if (this.body.sizes.sizeId.length > 0) {
        obj.sizes = {
          sizeId: { $in: this.body.sizes.sizeId }
        };
      }
      if (this.body.colorsList.colorId) {
        obj.colorsList = {
          colorId: this.body.colorsList.colorId
        };
      }

      if (this.body.price[0] != 15000 && this.body.price[1] != 77000) {
        obj.sizes.mainPrice = {
          $gte: this.body.price[0],
          $lte: this.body.price[1]
        };
      }


      const res = await this.$http.post(`packages/search?limit=12&page=${page}`, obj);
      return res.data.docs;
    },
    getData(page = 1) {
      let obj = {};
      if (this.body.categoriesId.length > 0) {
        obj.categoriesId = { $in: this.body.categoriesId };
      }
      if (this.body.subCategoriesId.length > 0) {
        obj.subCategoriesId = { $in: this.body.subCategoriesId };
      }
      if (this.body.vendorsId && this.body.vendorsId.length > 0) {
        obj.vendorsId = { $in: this.body.vendorsId };
      }
      if (this.body.materialsId.length > 0) {
        obj.materialsId = { $in: this.body.materialsId };
      }
      if (this.body.sizes.sizeId.length > 0) {
        obj.sizes = {
          sizeId: { $in: this.body.sizes.sizeId }
        };
      }
      if (this.body.colorsList.colorId) {
        obj.colorsList = {
          colorId: this.body.colorsList.colorId
        };
      }
      this.$http
        .post(`packages/search?limit=12&page=1`, obj)
        .then((res) => {
          this.list = res.data.docs;
          this.totalItemsCount = res.data.totalDocs;
          this.limit = res.data.limit;
        });
    }
  },
  created() {
    this.$http.get("categories?limit=1000000").then((res) => {
      this.categoriesList = res.data.docs;
    });
    this.$http.get("subcategories?limit=1000000").then((res) => {
      this.subcategoriesList = res.data.docs;
    });
    this.$http.get("sizes?limit=1000000").then((res) => {
      this.sizeList = res.data.docs;
    });
    this.$http.get("vendors?limit=1000000").then((res) => {
      this.vendorsList = res.data.docs;
    });

    this.$http.get("colors?limit=1000000").then((res) => {
      this.colorsList = res.data.docs;
    });
    this.$http.get("materials?limit=1000000").then((res) => {
      this.materialsList = res.data.docs;
    });

    this.getData();
  },
  watch: {
    "body.categoriesId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.subCategoriesId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.vendorsId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.materialsId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },

    "body.sizes.sizeId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },

    "body.colorsList.colorId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "this.body.price"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    }
  }
};
</script>

<style></style>
